.about_paragraph {
  margin-top: 20rem !important;
}
.about_paragraph p {
  font-size: 10rem !important;
}

.about-p {
  font-size: 14px;
}
#about {
  padding-bottom: 200px;
}

/* Réduire la margin-top à partir de 970px */
@media (max-width: 970px) {
  .about_paragraph {
    margin-top: 15rem !important; /* Réduire la marge pour les écrans inférieurs à 970px */
  }
}

/* Réduire davantage la margin-top pour les écrans encore plus petits */
@media (max-width: 768px) {
  .about_paragraph {
    margin-top: 10rem !important; /* Ajustement pour les tablettes et petits écrans */
  }
}

/* Réduire encore plus pour les très petits écrans */
@media (max-width: 576px) {
  .about_paragraph {
    margin-top: 5rem !important; /* Ajustement pour les smartphones */
  }
}
