@font-face {
  font-family: "semi-bold monserrat";
  src: url("../assets/fonts/Monserrat/Montserrat-SemiBold.ttf")
    format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "regular monserrat";
  src: url("../assets/fonts/Monserrat/Montserrat-Regular.ttf")
    format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "regular caslon";
  src: url("../assets/fonts/LibreCaslonText-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "italic caslon";
  src: url("../assets/fonts/LibreCaslonText-Italic.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Bold caslon";
  src: url("../assets/fonts/LibreCaslonText-Bold.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

:root {
  --dark-theme: #000000;
  --light-theme: #fff;
  --white_color: #fff;
  --dark-color: #000;
  --dore-color: #b4a14a;
  --font-ms-semi-bold: "semi-bold monserrat";
  --font-ms-regular: "regular monserrat";
  --font-bold-calson: "Bold caslon";
  --font-italic-calson: "italic caslon";
  --font-regular-calson: "regular caslon";
}

body {
  overflow-x: clip;
  margin: 0;
  background-color: var(--light-theme) !important;
  color: var(--dark-color) !important;
  font-family: var(--font-ms-regular) !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* grais des paragraphe augmentation de l'epaisseur des paragraphe */
p {
  font-weight: 700;
}

/*------------------------------*/
/*          GENERAL             */
/*------------------------------*/

#root {
  overflow-x: clip;
}

.logo-singe-2 {
  max-width: 100%;
}

#ce .logo-singe-3 {
  width: 15%;
}

/* couleur du h2 notre equipe et espace entreprisecouleur du h2 notre */
h2 {
  font-family: var(--font-regular-calson);
  font-size: 50px !important;
  color: black;
}

/* couleur du h2 à propos nos services et contact */
.h2--dore {
  font-family: var(--font-regular-calson);
  font-size: 50px !important;
  color: var(--dore-color) !important;
}

h3 {
  font-family: var(--font-ms-semi-bold);
  font-size: 20px !important;
}

.desktop {
  display: block !important;
}

.desktop-flex {
  display: flex !important;
}

.mobile {
  display: none !important;
}

.tablette {
  display: none !important;
}

.section-header {
  gap: 30px;
}

/*------------------------------*/
/*           HEADER             */
/*------------------------------*/

header {
  position: absolute;
  width: 100%;
  z-index: 10;
}

.navbar-collapse {
  display: flex;
  justify-content: flex-end;
}

.navbar-nav {
  gap: 20px;
}

/* nav {
  padding-top: 3rem !important;
} */

.nav-link {
  color: var(--white_color) !important;
}

.navbar-brand {
  height: 100px;
}

header .cercle img {
  max-width: 100%;
  width: 70%;
}

/*------------------------------*/
/*           BANNER             */
/*------------------------------*/

#banner {
  height: 100vh;
  display: flex;
  align-items: center;
  background-image: url("../assets/images/Banner/img-banner.png");
  background-repeat: no-repeat;
  background-size: cover;
  top: 0;
  right: 0;
  transition: 0.5s;
}

#banner h3 {
  font-family: var(--font-ms-regular);
  color: var(--white_color);
}

#banner h1 {
  font-size: 3.5rem;
  font-family: var(--font-regular-calson);
  color: var(--white_color);
}

#banner > div > div > div {
  position: absolute;
  bottom: 10%;
  left: 50%;
  transform: translateX(-50%);
}

.blur-back {
  filter: blur(5px);
  transition: 0.5s;
}

@keyframes bounce {
  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-10px);
  }
  60% {
    transform: translateY(-5px);
  }
}

.icon-banner {
  animation: bounce 2s infinite;
}

/*------------------------------*/
/*           ABOUT              */
/*------------------------------*/

#about {
  padding-bottom: 400px;
  padding-left: 1rem;
  padding-right: 1rem;
}

#about {
  padding-bottom: 400px;
}

.container-right {
  position: relative;
}

#about .image1 {
  width: 55%;
  position: absolute;
  left: 0;
  top: 300px;
  z-index: 2;
}

#about .image2 {
  width: 55%;
  position: absolute;
  left: 250px;
  top: 140px;
}

#about .sous-titre {
  font-size: 30px;
}

#about .text {
  font-size: 15px;
}

#about h3 {
  font-family: var(--font-regular-calson);
}

/*------------------------------*/
/*          SERVICES            */
/*------------------------------*/

#services {
  padding-top: 0px;
  padding-bottom: 200px;
  margin-top: 5rem;
}
.card-container {
  gap: 30px;
}

.c-card h3 {
  font-size: 35px;
  font-family: var(--font-regular-calson);
}

/* modif */
#services h3 {
  font-size: 24px;
}
/* modif */
.c-card p {
  font-size: 12px;
  margin-bottom: 4rem;
}

.c-card {
  height: 468px;
  border-radius: 20px;
  position: relative;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  color: var(--light-theme);
}

.c-card.c-offset {
  margin-top: 40px;
}

/* modif */

.c-card-desktop {
  width: 29% !important;
}

.c-card h3 {
  margin-top: 80%;
}

/* modif */

.c-card-1 {
  background-image: url("../assets/images/Services/service1.png");
}

.c-card-2 {
  background-image: url("../assets/images/Services/service2.png");
}

.c-card-3 {
  background-image: url("../assets/images/Services/service3.png");
}

.l-btn-contact {
  text-decoration: none;
}

.btn-contact {
  width: 250px;
  border: 2px solid var(--dore-color);
  background-color: transparent;
  color: var(--dore-color);
  height: 58px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.5s;
}

.btn-contact a {
  color: inherit;
  text-decoration: none;
}

.btn-contact:hover,
.btn-contact a:hover {
  background-color: var(--dore-color) !important;
  color: var(--white_color) !important;
  transition: 0.5s;
}

.div-contact {
  text-align: center;
}

/*------------------------------*/
/*      entreprise              */
/*------------------------------*/

#entreprises {
  padding-top: 0px;
  padding-bottom: 200px;
}

#entreprises h2 {
  color: black !important;
}

.img-entreprise {
  max-width: 100%;
}

.entreprise-icon {
  padding-bottom: 20px;
}

.entreprise-container {
  gap: 100px;
}

.info-entreprise {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

/* modif */

.entreprise-event {
  position: relative;
  color: var(--white_color);
  padding: 150px;
  background-image: url("../assets/images/Entreprise/Event-bg.png");
  background-size: cover; /* Assure que l'image couvre bien tout l'élément */
  background-repeat: no-repeat;
  z-index: 1;
  box-sizing: border-box; /* Le padding n'ajoutera pas de taille supplémentaire */
}

/* modif */

.entreprise-event::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: -1;
}

/* modif */
.white-text {
  color: var(--white_color);
}

/*--------------------*/
/*      CONTACT       */
/*--------------------*/

#contact {
  padding-top: 0px;
  /* font-family: var(--font-neue-kaine); */
}

.separateur-contact {
  border: none;
  border-left: 6px solid #fff;
  height: 550px;
  width: 1px;
  opacity: 0.75;
}

.container-contact-info {
  display: flex;
  flex-direction: column;
  white-space: nowrap;
  display: inline-block;
}

/* modif */

#contact a {
  text-decoration: none;
  color: var(--dark-color);
}

.contact-sstitre {
}

.adresse a,
.support div,
.ouverture div {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

hr {
  margin: 0 !important;
}

.container-contact-info {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

#contact button {
  width: 100%;
  border: 1px solid var(--dark-color);
  background-color: var(--dark-color);
  color: var(--white_color);
  height: 58px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.5s;
}

.contact-form {
  display: flex;
  gap: 30px;
  flex-direction: column;
}

/* modif */

.form-control {
  background-color: transparent !important;
  border: none !important;
  border-bottom: 1px solid var(--dark-color) !important;
  border-radius: 0px !important;
  color: var(--dark-color) !important;
  margin-bottom: 50px;
}

.form-control:focus {
  box-shadow: 0 0 0 0.25rem rgba(255, 255, 255, 0.75) !important;
}

/* modif */

.btn-submit:hover {
  background-color: var(--white_color) !important;
  color: var(--dark-color) !important;
  transition: 0.5s;
}

/* Pour les navigateurs modernes */
#formName::placeholder,
#formEmail::placeholder,
#formMessage::placeholder {
  color: var(--dark-color) !important;
  opacity: 0.8;
}

/*------------------------------*/
/*           FOOTER             */
/*------------------------------*/

/* code pour garder l'adresse mail sur une ligne */

footer {
  padding-top: 300px;
}

footer a {
  text-decoration: none;
  color: var(--dark-color);
}

footer a:hover {
  color: var(--dore-color);
}

footer hr {
  padding-top: 40px;
  border: none;
  border-bottom: 3px solid var(--dore-color);
  opacity: 0.75;
}

footer img {
  max-width: 100%;
}

footer .follow-us {
  color: var(--dore-color);
  font-family: var(--font-regular-calson);
}
