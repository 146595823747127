:root {
  --dark-theme: #000000;
  --white_color: #fff;
  --font-mk: "DK Lemon Yellow Sun";
  --font-ms-semi-bold: "semi-bold monserrat";
  --font-ms-regular: "regular monserrat";
}

/* Pour les très petits écrans comme les téléphones */
@media (max-width: 480px) {
  #banner h1 {
    font-size: 20px;
  }

  #banner h3 {
    font-size: 12px;
  }

  .icon-banner {
    width: 50px; /* Réduit la taille de l'icône sur mobile */
  }
}
/* Media queries pour téléphones */
@media (max-width: 767px) {
  #banner {
    background-position: top;
    height: 80vh;
  }

  #banner h1 {
    font-size: 24px;
  }

  #banner h3 {
    font-size: 14px;
  }

  .desktop {
    display: none !important;
  }

  .desktop-flex {
    display: none !important;
  }

  .mobile {
    display: block !important;
  }

  .tablette {
    display: none !important;
  }

  h2 {
    font-size: 32px !important;
  }

  h3 {
    font-size: 16px !important;
  }

  header .cercle {
    width: 150px;
    height: 150px;
  }

  /*------------------------------*/
  /*           BANNER             */
  /*------------------------------*/

  #banner {
    height: 60vh;
    text-align: center;
  }

  #banner img {
    width: 100%;
  }

  #banner > div > div > div {
    position: absolute;
    bottom: auto;
    left: 50%;
    transform: translateX(-50%);
  }

  #banner .row {
    position: relative;
  }

  /*------------------------------*/
  /*           ABOUT              */
  /*------------------------------*/

  /* .container-right-fond {
        background-color: var(--dark-theme);
    } */
  /* .container-about div {
        padding-bottom: 0px !important;
    } */

  #about .offset-1 {
    margin-left: 0;
  }

  .logo-singe-1 {
    max-width: 100%;
  }
  #about .image1 {
    width: 85%;
  }

  .container-right {
    position: static;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 50px;
  }

  #about .image1 {
    position: static;
  }

  #about {
    padding-bottom: 100px;
  }

  /*------------------------------*/
  /*          SERVICES            */
  /*------------------------------*/

  #services {
    padding-top: 0px;
    padding-bottom: 100px !important;
  }

  .c-card p {
    margin-bottom: 2rem;
  }

  #services h3 {
    font-size: 20px !important;
  }

  #services .section-header > div {
    align-items: flex-start !important;
  }

  .c-card-desktop {
    width: 80% !important;
    background-size: cover;
  }

  .c-card {
    height: auto;
    border-radius: 0px;
  }

  .c-card h3 {
    align-self: center;
  }

  .c-card.c-offset {
    margin-top: 0px;
  }

  /*------------------------------*/
  /*             entreprises              */
  /*------------------------------*/

  #entreprises {
    padding-top: 0px !important;
    padding-bottom: 100px;
  }

  /* #entreprises div div {
        padding-bottom: 0px !important;
    } */

  .logo-singe-3 {
    max-width: 100%;
  }

  /* #entreprises > div > div > div {
        position: absolute;
        bottom: 50%;
        left: 50%;
        transform: translateX(-50%);
    } */

  /*------------------------------*/
  /*             CE               */
  /*------------------------------*/

  #ce .container-contact-btn {
    justify-content: center !important;
    padding-bottom: 50px;
  }
  #ce {
    padding-top: 0px !important;
    padding-bottom: 150px;
  }

  /*------------------------------*/
  /*           CONTACT            */
  /*------------------------------*/

  #contact {
    padding-top: 0px;
  }

  /*------------------------------*/
  /*           FOOTER             */
  /*------------------------------*/

  footer {
    padding-top: 150px;
  }

  /* modif */
  .edhec-logo {
    margin-top: 25px;
    height: 40px;
  }
}

/* Media queries pour tablettes */
@media (min-width: 768px) and (max-width: 1024px) {
  .desktop {
    display: none !important;
  }

  .desktop-flex {
    display: none !important;
  }

  .mobile {
    display: block !important;
  }

  .tablette {
    display: block !important;
  }

  /*------------------------------*/
  /*           BANNER             */
  /*------------------------------*/

  #banner {
    height: 60vh;
  }

  #banner > div > div > div {
    position: absolute;
    bottom: 50%;
    left: 50%;
    transform: translateX(-50%);
  }

  /*------------------------------*/
  /*           ABOUT              */
  /*------------------------------*/

  /* .container-right-fond {
        background-color: var(--dark-theme);
    } */

  .container-right {
    position: static;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  #about .image1 {
    position: static;
  }

  /*------------------------------*/
  /*          SERVICES            */
  /*------------------------------*/

  .c-card-desktop {
    width: 45% !important;
  }

  /*------------------------------*/
  /*             entreprises              */
  /*------------------------------*/

  /* #entreprises div div {
        padding-bottom: 0px !important;
    } */

  /*------------------------------*/
  /*              CE              */
  /*------------------------------*/

  #ce .container-contact-btn {
    justify-content: center !important;
    padding-bottom: 50px;
  }

  .img-ce {
    display: flex;
    justify-content: center;
  }

  footer .info {
    justify-content: space-between;
  }
}
