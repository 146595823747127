h2 {
  color: black;
}

.title-left {
  margin-top: 550px; /* Ajuste la valeur pour descendre le titre */
}
.notreequipe-p {
  font-size: 14px;
}
.image3 {
  width: 120px; /* Ajuste la largeur selon tes besoins */
  height: auto; /* Conserve le ratio d'aspect */
  position: relative; /* Nécessaire pour utiliser top et left */

  left: 150px; /* Déplace l'image vers la droite de 100 pixels */
}

.image4 {
  width: 120px; /* Ajuste la largeur selon tes besoins */
  height: auto; /* Conserve le ratio d'aspect */
  position: relative; /* Nécessaire pour utiliser top et left */
  right: 100px; /* Déplace l'image vers la gauche de 100 pixels */
}

/* Écrans moyens (entre 992px et 1199px) */
@media (max-width: 1199px) and (min-width: 992px) {
  .image1,
  .image2 {
    width: 50%; /* Les images prennent chacune 50% de la largeur */
    margin-bottom: 20px; /* Espacement entre les images */
  }

  .d-flex {
    flex-direction: row; /* Les images sont côte à côte */
  }

  .title-left {
    margin-top: 30px; /* Ajuster la marge si nécessaire */
  }
}

/* Écrans petits (entre 768px et 991px) */
@media (max-width: 991px) and (min-width: 768px) {
  .image1,
  .image2 {
    width: 70%; /* Les images prennent toute la largeur */
    margin-bottom: 20px; /* Espacement entre les images */
  }

  .d-flex {
    flex-direction: column; /* Les images passent en colonne */
  }

  .title-left {
    margin-top: 30px;
  }
}

/* Écrans très petits (moins de 768px) */
@media (max-width: 768px) {
  .image3,
  .image4 {
    display: none; /* Cacher les images des flèches */
  }

  .title-left {
    margin-top: 40px;
  }

  .d-flex {
    flex-direction: column;
    align-items: center; /* Les images sont empilées en colonne */
  }

  .image1,
  .image2 {
    width: 70%; /* Les images prennent toute la largeur */
    margin-bottom: 20px; /* Espacement entre les images */
  }
}
